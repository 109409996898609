<section id="dados-cartao">
  <div class="d-flex justify-content-between">
    <a class="link cursor-pointer text-decoration-underline" style="color: #007DB9;"
      (click)="preencherFormComDadosDoUsuario()">Utilizar os dados do usuário</a>
    <a [href]="helpLink" target="_blank" ngbTooltip="Clique para obter ajuda" placement="right" style="color: #007DB9;">
      <i-feather name="help-circle" class="fea icon-sm icons cursor-pointer"></i-feather>
    </a>
  </div>
  <form [formGroup]="formDadosAssinatura">
    <div class="row g-3">
      <div class="col-12 col-md-6">
        <label class="form-label">{{labelNome}} <b class="text-danger">*</b></label>
        <input type="text" class="form-control form-control-sm" formControlName="nomeTitular" id="nomeTitular"
          [ngClass]="formReativo.definindoClasseValidator(formDadosAssinatura, 'nomeTitular')"
          (keyup)="emitirDadosAssinatura()" autocomplete="off" autofocus>
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label">CPF: <b class="text-danger">*</b></label>
        <input type="text" class="form-control form-control-sm" formControlName="cpfCnpjTitular"
          (input)="masksUtils.maskCpf($event)" id="cpfCnpjTitular" (keyup)="proximoInput('cpfCnpjTitular', 11, 'email')"
          (keyup)="emitirDadosAssinatura()"
          [ngClass]="formReativo.definindoClasseValidator(formDadosAssinatura, 'cpfCnpjTitular')" autocomplete="off">
        @if(controls['cpfCnpjTitular'].dirty){
        @if(controls['cpfCnpjTitular'].errors?.['required']){
        <small class="text-danger">*Campo obrigatório.</small>
        }
        }
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label">E-mail: <b class="text-danger">*</b></label>
        <input type="email" class="form-control form-control-sm" formControlName="emailTitular"
          [ngClass]="formReativo.definindoClasseValidator(formDadosAssinatura, 'emailTitular')"
          (keyup)="emitirDadosAssinatura()" id="email" autocomplete="off">
        @if(controls['emailTitular'].errors && controls['emailTitular'].dirty){
        <small class="text-danger">*Campo obrigatório.</small>
        }
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label">
          Telefone Celular: <b class="text-danger">*</b>
        </label>
        <input type="text" class="form-control form-control-sm" formControlName="celular" id="celular"
          (input)="masksUtils.maskPhone($event)" pattern="\d{11}" (keyup)="emitirDadosAssinatura()"
          [ngClass]="formReativo.definindoClasseValidator(formDadosAssinatura, 'celular')" autocomplete="off">
        @if(controls['celular'].dirty){
        @if(controls['celular'].errors?.['required']){
        <small class="text-danger">*Campo obrigatório.</small>
        }@else if (controls['celular'].errors?.['minlength'] || controls['celular'].errors?.['maxlength']) {
        <small class="text-danger">
          *Informe um número válido. Dica: verifique se o 9º dígito foi informado.
        </small>
        }
        }
      </div>
    </div>

    <div class="row g-3 my-3">
      <div class="col">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <li ngbNavItem>
            <button ngbNavLink (click)="isGerarLinkPagamento = false" (click)="onChangeGerarLinkPagamento()">Pagar Por Aqui</button>
            <ng-template ngbNavContent>
              @if(isGerarLinkPagamento == false){
              <p class="text-muted px-3">
                <small>
                  Você está realizando o pagamento pela extratoofx, em conjunto com a nossa parceira ASAAS, em um
                  ambiente
                  seguro.
                </small>
              </p>
              <div class="row g-3 p-3">
                <div class="col-12 col-md-5 col-lg-6">
                  <label class="form-label">Número do cartão: <b class="text-danger">*</b></label>
                  <input type="text" class="form-control form-control-sm" formControlName="numeroCartao"
                    pattern="\d{16}" (input)="masksUtils.maskNumeroCartao($event)" maxlength="16"
                    (keyup)="emitirDadosAssinatura()"
                    [ngClass]="formReativo.definindoClasseValidator(formDadosAssinatura, 'numeroCartao')"
                    id="numeroCartao" (keyup)="proximoInput('numeroCartao', 16, 'cvv')" autocomplete="off">
                  @if(controls['numeroCartao'].dirty && controls['numeroCartao'].touched){
                  @if(controls['numeroCartao'].errors?.['minlength'] ||
                  controls['numeroCartao'].errors?.['maxlength']){
                  <small class="text-danger">*Informe um cartão válido.</small>
                  }
                  }
                </div>
                <div class="col-5 col-md-3 col-lg-2">
                  <label class="form-label">CVV: <b class="text-danger">*</b></label>
                  <input type="text" class="form-control form-control-sm" formControlName="cvv" pattern="\d{3}"
                    maxlength="3" [ngClass]="formReativo.definindoClasseValidator(formDadosAssinatura, 'cvv')"
                    (keyup)="emitirDadosAssinatura()" id="cvv" (keyup)="proximoInput('cvv', 3, 'validade')"
                    autocomplete="off">
                  @if(controls['cvv'].dirty){
                  @if(controls['cvv'].errors?.['required']){
                  <small class="text-danger">*Campo obrigatório.</small>
                  }
                  }
                </div>
                <div class="col-7 col-md-4 col-lg-4">
                  <label class="form-label">Validade: <b class="text-danger">*</b></label>
                  <input type="text" class="form-control form-control-sm" formControlName="validade" pattern="\d{6}"
                    (input)="masksUtils.maskMesAno($event)" maxlength="6" (keyup)="emitirDadosAssinatura()" (keyup)="proximoInput('validade', 6, 'cepTitular')"
                    [ngClass]="formReativo.definindoClasseValidator(formDadosAssinatura, 'validade')" id="validade"
                    autocomplete="off" placeholder="MM/AAAA">
                  @if(controls['validade'].dirty){
                  @if(controls['validade'].errors?.['required']){
                  <small class="text-danger">*Campo obrigatório.</small>
                  }@else if (controls['validade'].errors?.['notEqual']){
                  <small class="text-danger">*Necessário que a validade seja maior que o mês atual.</small>
                  }
                  }
                </div>
              </div>
              <div class="row g-3 p-3 pt-0">
                <div class="col-12 col-md-4 col-lg-3">
                  <label class="form-label">
                    CEP: <b class="text-danger">*</b>
                  </label>
                  <input type="text" class="form-control form-control-sm" formControlName="cepTitular"
                    (input)="masksUtils.maskCep($event)" maxlength="8" (change)="aoInformarOCep()"
                    (keyup)="proximoInput('cepTitular', 8, 'numeroEndereco')" id="cepTitular"
                    [ngClass]="formReativo.definindoClasseValidator(formDadosAssinatura, 'cepTitular')">
                  @if(controls['cepTitular']?.dirty){
                  @if(controls['cepTitular'].errors?.['required']){
                  <small class="text-danger">*Campo obrigatório.</small>
                  }@else if(!isCepValido) {
                  <small class="text-danger">*Informe um CEP válido.</small>
                  }
                  }
                </div>
                <div class="col-8 col-md-8 col-lg-6">
                  <label class="form-label">
                    Logradouro:
                  </label>
                  <input type="text" class="form-control form-control-sm" formControlName="logradouro"
                    (change)="emitirDadosAssinatura()">
                </div>
                <div class="col-4 col-md-2 col-lg-3">
                  <label class="form-label">
                    Nº: <b class="text-danger">*</b>
                  </label>
                  <input type="text" class="form-control form-control-sm" formControlName="numeroEndereco"
                    [ngClass]="formReativo.definindoClasseValidator(formDadosAssinatura, 'numeroEndereco')"
                    (keyup)="emitirDadosAssinatura()" id="numeroEndereco">
                  @if(controls['numeroEndereco']?.errors && controls['numeroEndereco']?.dirty){
                  <small class="text-danger">*Campo obrigatório.</small>
                  }
                </div>
                <div class="col-6 col-md-4">
                  <label class="form-label">
                    Bairro:
                  </label>
                  <input type="text" class="form-control form-control-sm" formControlName="bairro"
                    (change)="emitirDadosAssinatura()">
                </div>
                <div class="col-6 col-md-3 col-lg-4">
                  <label class="form-label">
                    Cidade:
                  </label>
                  <input type="text" class="form-control form-control-sm" formControlName="cidade"
                    (change)="emitirDadosAssinatura()">
                </div>
                <div class="col-6 col-md-3 col-lg-4">
                  <label class="form-label">
                    Estado:
                  </label>
                  <input type="text" class="form-control form-control-sm" formControlName="estado"
                    (change)="emitirDadosAssinatura()">
                </div>
              </div>
              }
            </ng-template>
          </li>
          <li ngbNavItem>
            <button ngbNavLink (click)="isGerarLinkPagamento = true" (click)="onChangeGerarLinkPagamento()">Link de Pagamento</button>
            <ng-template ngbNavContent>
              <div class="row p-3">
                <div class="col">
                  <p>
                    Optando por receber o link de pagamento, ao finalizar o preenchimento dos campos necessários neste
                    formulário, você receberá um email da ASAAS, nossa gestora de cobrança, para finalizar o pagamento
                    da assinatura.
                  </p>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div [ngbNavOutlet]="nav"></div>
    </div>

    <div class="row">
      <div class="col-12 text-end">
        <a class="link cursor-pointer text-decoration-underline" (click)="emitirNF()">Emitir Nota Fiscal?</a>
      </div>
      @if(isEmitirNf){
      <caption class="text-nowrap mt-0">Dados Emissão NF</caption>
      <app-form-emissao-nf [emailUsuario]="emailUsuario"></app-form-emissao-nf>
      }
    </div>
  </form>
</section>