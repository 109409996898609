import { Component, OnInit, TemplateRef } from '@angular/core';
import { setThrowInvalidWriteToSignalError } from '@angular/core/primitives/signals';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { SecurityUtil } from 'src/app/_core/utils/security.util';
import { LoginComponent } from 'src/app/auth/login/login.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/**
 * Home Component
 */
export class HomeComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas
  ) { }

  /**
   * variáveis lidas pelo NavBar na inicialização do component
   * vide AppComponent, no evento onActivate
   */
  isCondensed = false;
  currentSection = 'home';
  Menuoption = 'home';
  Settingicon = true
  hideFooter = false;
  footerVariant = 'home';

  //variáveis identificacao de dispositivo
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktop: boolean = false;

  /**
   * Member Data
   */
  memberData = [
    {
      profile: "assets/imgs/client/01.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ronny Jofra",
      designation: "C.E.O"
    },
    {
      profile: "assets/imgs/client/04.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Micheal Carlo",
      designation: "Director"
    },
    {
      profile: "assets/imgs/client/02.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Aliana Rosy",
      designation: "Manager"
    },
    {
      profile: "assets/imgs/client/03.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Sofia Razaq",
      designation: "Developer"
    }
  ];

  ngOnInit(): void {
    //Setando o tipo de dispositivo
    this.isMobile = window.innerWidth < 768;
    this.isTablet = (window.innerWidth >= 768 && window.innerWidth < 992);
    this.isDesktop = window.innerWidth >= 992;
    if(!SecurityUtil.hasToken){
      LoginComponent.reiniciarUserLogado();
    }

    if(sessionStorage.getItem('planoSelecionado')){
      sessionStorage.removeItem('planoSelecionado')
    }
  }

  mapView(mapcontent) {
    this.modalService.open(
      mapcontent,
      { windowClass: 'dark-modal', size: 'lg', centered: true }
    );
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(
      content,
      { windowClass: 'dark-modal', size: 'lg', centered: true }
    );
  }
  openTrialModal(content: any) {
    this.modalService.open(
      content, { centered: true }
    );
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      document.getElementById('topnav').classList.remove('nav-sticky');
    }

    if (document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100) {
      document.getElementById('back-to-top').style.display = 'inline';
    } else {
      document.getElementById('back-to-top').style.display = 'none';
    }
  }

  /**
  * Section changed method
  * @param sectionId specify the current sectionID
  */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }


  // Demo Offcanvas
  openright(content: TemplateRef<any>) {
    this.offcanvasService.open(
      content,
      { position: 'end' }
    );
  }
}
