<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()">
  <div class="container d-flex justify-content-between align-items-center"
    [ngClass]="{'mt-3' : (isMobile && Menuoption === 'home')}">
    <div>
      @if(Menuoption === 'home'){
      <a [ngxScrollTo]="'#home'">
        @if(isMobile){
        <img src="assets/imgs/extratoofx-color.png" class="navbar-brand" height="40" alt="logo da extrato ofx">
        }@else {
        <img src="assets/imgs/extratoofx-color.png" class="navbar-brand" height="60" alt="logo da extrato ofx">
        }
      </a>
      }@else{
      <a [routerLink]="linkRedirecionamento">
        <img src="assets/imgs/extratoofx-color.png" class="navbar-brand" height="40" alt="logo da extrato ofx">
      </a>
      }
    </div>

    <!-- @if((Menuoption === 'conversor' || Menuoption === 'contratar') && isMobile){
    <div class="menu-extras">
      <div class="menu-item">
        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>
    } -->


    <div id="navigation">
      <ul class="navigation-menu">
        @if(Menuoption === 'conversor'){
        <li><a routerLink="/assinantes/conversor" class="nav-link-ref">Converter</a></li>
        <li><a href="https://doc.extratoofx.com" class="nav-link-ref" target="_blank">Como Funciona</a></li>
        <li><a routerLink="/assinantes/meu-perfil" class="nav-link-ref">Meu Perfil</a></li>
        @if(isTesteOrReteste){
        <li><a routerLink="/assinantes/contratar" class="nav-link-ref">Contratar</a></li>
        }
        @if (!isDesktop) {
        <li><a (click)="fazerLogout()" class="nav-link-ref">Sair</a></li>
        }
        }@else if (Menuoption === 'contratar') {
        <li><a routerLink="/assinantes/conversor" class="nav-link-ref">Converter</a></li>
        <li><a href="https://doc.extratoofx.com" class="nav-link-ref" target="_blank">Como Funciona</a></li>
        <li><a routerLink="/assinantes/meu-perfil" class="nav-link-ref">Meu Perfil</a></li>
        @if (!isDesktop) {
        <li><a (click)="fazerLogout()" class="nav-link-ref">Sair</a></li>
        }
        }
      </ul>
    </div>

    @if(Settingicon == true && isDesktop!){
    <ul class="buy-button list-inline mb-0" [ngClass]="{'navigation-menu' : (Menuoption === 'conversor' || Menuoption === 'contratar')}">
      @if(Menuoption === 'home'){
      <li class="list-inline-item mb-0">
        <a href="javascript:void(0)" routerLink="/auth/login">
          <div class="btn btn-primary" id="settingbtn">
            Entrar <i-feather name="log-in" class="fea icon-sm"></i-feather>
          </div>
        </a>
      </li>
      }@else{
      <li class="list-inline-item mb-0">
        <a href="javascript:void(0)" (click)="fazerLogout()">
          <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn">
            <i-feather name="log-out" class="fea icon-sm"></i-feather>
          </div>
        </a>
      </li>
      }
    </ul>
    }
    <!-- @else {
    @if(Menuoption === 'home'){
    <li class="list-inline-item mb-0">
      <a href="javascript:void(0)" (click)="abrirModalLogin()">
        <button type="button" class="btn btn-primary btn-sm" id="settingbtn">
          Entrar <i-feather name="log-in" class="fea icon-sm"></i-feather>
        </button>
      </a>
    </li>
    }
    } -->
  </div>
</header>
