<section class="container d-none d-md-block">
  <div class="row g-3 bg-transparent align-items-center justify-content-center">
    @for (imagem of carroselImagens; track $index) {
    <div class="col-md-4 col-lg-3 col-xl-2 text-center bg-transparent filter-img">
      <img class="pb-2" [src]="imagem.urlImagem" [height]="imagem.altura" [name]="imagem.nome" [alt]="imagem.nome" />
    </div>
    }
  </div>
  <div class="row">
    <div class="col">
      <p class="text-center pt-2 text-muted para-desc mb-0 mx-auto d-block">
        Não encontrou seu banco? não se preocupe! Estamos em constante desenvolvimento e você pode nos ajudar a
        expandir este catálogo!
      </p>
    </div>
  </div>
</section>
<section class="container-fluid d-md-none">
  <div class="row">
    <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="true" [pauseOnFocus]="false"
      [pauseOnHover]="false" [interval]="1000">
      @for (imagem of carroselImagens; track $index) {
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper text-center filter-img">
          <img [src]="imagem.urlImagem" [alt]="imagem.nome" [height]="imagem.altura" [name]="imagem.nome" />
        </div>
        <div class="carousel-caption">
          <p class="text-dark">{{imagem.nome}}</p>
        </div>
      </ng-template>
      }
    </ngb-carousel>
    <small class="text-center pt-5 text-muted para-desc mb-0 mx-auto">
      Não encontrou seu banco? não se preocupe! Estamos em constante desenvolvimento e você pode nos ajudar a
      expandir este catálogo!
    </small>
  </div>
</section>