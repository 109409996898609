import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';

import { ScrollspyDirective } from './scrollspy.directive';
import { FeatherModule } from 'angular-feather';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ValoresV1Component } from './components/valores-v1/valores-v1.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { PagetitleComponent } from './components/pagetitle/pagetitle.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MasksUtil } from './services/masks.util';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ViaCepService } from './services/via-cep.service';
import { BotaoBackToTopComponent } from './components/botao-back-to-top/botao-back-to-top.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { PageAvisoDispositivosMobileETabletComponent } from './components/page-aviso-dispositivos-mobile-e-tablet/page-aviso-dispositivos-mobile-e-tablet.component';
import { TelefonePipe } from './pipe/telefone.pipe';

@NgModule({
  declarations: [
    ScrollspyDirective,
    HeaderComponent,
    ValoresV1Component,
    FooterComponent,
    TestimonialComponent,
    PagetitleComponent,
    PageNotFoundComponent,
    NavBarComponent,
    BotaoBackToTopComponent,
    PageAvisoDispositivosMobileETabletComponent,
    TelefonePipe
  ],
  imports: [
    CommonModule,
    CarouselModule,
    FeatherModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    ScrollToModule
  ],
  exports: [
    ScrollspyDirective,
    HeaderComponent,
    ValoresV1Component,
    FooterComponent,
    TestimonialComponent,
    NavBarComponent,
    PagetitleComponent,
    BotaoBackToTopComponent,
    PageAvisoDispositivosMobileETabletComponent,
    TelefonePipe
  ],
  providers: [
    MasksUtil,
    ViaCepService
  ]
})

export class SharedModule { }
