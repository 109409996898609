import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-bancos',
  templateUrl: './logo-bancos.component.html',
  styleUrls: ['./logo-bancos.component.css']
})
export class LogoBancosComponent implements OnInit {
  /**
     * Imagens do Carrosel
     */
  carroselImagens: {
    urlImagem: string,
    nome: string,
    altura: number,
  }[] = [
      {
        urlImagem: "/assets/imgs/bancos/banco-original-sem-bg.png",
        nome: 'Banco Original',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/bb.png",
        nome: 'Banco do Brasil',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/logo-mercado-pago-1024.png",
        nome: 'Mercado Pago',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/nu.png",
        nome: 'Nubank',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/pagbank-logo-1-1.png",
        nome: 'PagBank',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/banco-inter-logo.png",
        nome: 'Banco Inter',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/itau.png",
        nome: 'Banco Itaú',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/caixa.png",
        nome: 'Caixa (CEF)',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/bradesco.png",
        nome: 'Bradesco',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/QI-Tech-Logo-360x119.png",
        nome: 'Qi Sociedade de Crédito',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/banrisul-logo.png",
        nome: 'Banco Banrisul',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/banco-safra.png",
        nome: 'Banco Safra',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/santander-sem-bg.png",
        nome: 'Banco Santander',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/banco-do-nordeste-logo.png",
        nome: 'Banco do Nordeste',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/sicoob-logo-1-1.png",
        nome: 'Banco Sicoob',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/sicredi-sem-bg.png",
        nome: 'Banco Sicredi',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/asaas-logo.png",
        nome: 'Asaas',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/c6-bank-logo.png",
        nome: 'C6 Bank',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/banco-picpay-logo.png",
        nome: 'Banco PicPay',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/infinite-pay-logo.png",
        nome: 'Banco Infinite Pay',
        altura: 50,
      },
      {
        urlImagem: "/assets/imgs/bancos/stone-logo.png",
        nome: 'Banco Stone',
        altura: 50,
      },
    ];
  imagensPorSlide: number;

  constructor() { }

  ngOnInit(): void {
    this.setImagensPorSlide(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setImagensPorSlide(event.target.innerWidth);
  }

  setImagensPorSlide(largura: number) {
    if(largura < 768){
      this.imagensPorSlide = 1;
    }else if(largura >= 768 && largura < 1024){
      this.imagensPorSlide = 2;
    }
  }

  getImageGroups(): any[] {
    const grupos: any[] = [];
    for (let i = 0; i < this.carroselImagens.length; i += this.imagensPorSlide) {
      grupos.push(this.carroselImagens.slice(i, i + this.imagensPorSlide));
    }

    return grupos;
  }
}