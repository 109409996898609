<section id="modal-obter-account modal-content">
  <div class="modal-header d-flex justify-content-between">
    <h5>Fazer login</h5>
  </div>
  <div class="modal-body">
    <span class="text-muted">
      Login necessário para identificação do usuário.
    </span>
    <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">E-mail: <span class="text-danger">*</span></label>
            <div class="form-icon position-relative">
              <i-feather name="mail" class="fea icon-sm icons"></i-feather>
              <input class="form-control ps-5" type="email" placeholder="ex.: talita@contabil.com"
                formControlName="email" id="email-acesso" ngbAutofocus>
              @if (loginForm.controls['email'].touched
              && loginForm.controls['email'].errors?.['required']) {
              <div class="text text-danger mt-1">*E-mail obrigatório.</div>
              }
              @if (loginForm.controls['email'].touched
              && loginForm.controls['email'].errors?.['email']) {
              <div class="text text-danger mt-1">*Informe um email válido.</div>
              }
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">Senha: <span class="text-danger">*</span></label>
            <div class="form-icon position-relative">
              <i-feather name="key" class="fea icon-sm icons"></i-feather>
              <input class="form-control ps-5" [type]="mostrar ? 'text' : 'password'" placeholder="*********"
                formControlName="password">
              @if (loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['required']) {
              <div class="text text-danger mt-1">
                Senha obrigatória
              </div>
              }
            </div>
          </div>
          <div>
            <div class="form-check">
              <input type="checkbox" id="view-password" class="form-check-input cursor-pointer"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="mostrar">
              <label for="view-password" class="form-check-label cursor-pointer">
                {{mostrar ? 'Esconder senha' : 'Mostrar senha'}}
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-12 mt-2 mb-0">
          <div class="d-grid">
            <button class="btn btn-primary" type="submit" [disabled]="!loginForm.valid">
              Entrar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>