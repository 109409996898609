import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public eventLoader = new EventEmitter<boolean>();
  /**Subject para comunicação */
  isShowMessage = new BehaviorSubject<boolean>(true);
  mensagemAVisualizar = new BehaviorSubject<string>(
    'Aguarde... Estamos processando sua solicitação.'
  );

  /**
   * Função utilizada para iniciar o componente de loader na tela.
   * @param isShowMessage parâmetro opcional, que define se a mensagem 'Aguarde...' será
   * visualizada na tela. O padrão é 'true', ou seja, por padrão, a mensagem é visualizada.
   * @method
   */
  public startLoader(isShowMessage?: boolean, messagemAVisualizar?: string) {
    if(isShowMessage !== undefined) {
      this.isShowMessage.next(isShowMessage);
    }

    if(messagemAVisualizar!){
      this.mensagemAVisualizar.next(messagemAVisualizar);
    }
    
    this.setEventLoader(true);
  }

  /**
   * Função utilizada para finalizar o componente de loader
   * @param time parâmetro opcional, que define se será será adicionado
   * um timeOut para realizar o StopLoader.
   */
  public stopLoader(time?: number) {
    if (time) {
      setTimeout(() => {
        this.setEventLoader(false);
      }, time);
    } else {
      this.setEventLoader(false);
    }
  }

  setEventLoader(event: boolean) {
    this.eventLoader.emit(event);
  }
}
