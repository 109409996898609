import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NaoAssinantesRoutingModule } from './nao-assinantes.routing';
import { RenovarContratacaoComponent } from './renovar-contratacao/renovar-contratacao.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoaderComponent } from 'src/app/_core/loader/loader.component';
import { PrecosComponent } from 'src/app/pages/components/precos/precos.component';
import { ContratarAssinaturaComponent } from '../assinantes/contratar/form-contratar-assinatura/contratar-assinatura.component';
import { FormNovoParceiroComponent } from './form-novo-parceiro/form-novo-parceiro.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabelaClientesAvaliacaoComponent } from './tabela-clientes-avaliacao/tabela-clientes-avaliacao.component';


@NgModule({
  declarations: [
    RenovarContratacaoComponent,
    FormNovoParceiroComponent,
  ],
  imports: [
    CommonModule,
    NaoAssinantesRoutingModule,
    SharedModule,
    LoaderComponent,
    PrecosComponent,
    ContratarAssinaturaComponent,
    FormsModule,
    ReactiveFormsModule,
    TabelaClientesAvaliacaoComponent
  ]
})
export class NaoAssinantesModule { }
