import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONVERSOR } from 'src/app/_core/api';
import { ResultV1Model } from 'src/app/shared/models/result-v1.model';

@Injectable({
  providedIn: 'root'
})
export class NaoAssinantesV1Service {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAccountsEmAvaliacao(){
    return this.httpClient.get<ResultV1Model>(
      `${API_CONVERSOR}/v1/account-avaliacao/lista`
    )
  }
}
