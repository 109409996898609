import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountV1Model } from 'src/app/auth/models/account-v1.model';
import { PrecosComponent } from 'src/app/pages/components/precos/precos.component';
import { PlanoSelecionadoV1Model } from 'src/app/pages/models/plano-selecionado-v1.model';
import { DetalhesAssinaturaComponent } from './detalhes-assinatura/detalhes-assinatura.component';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { MasksUtil } from 'src/app/shared/services/masks.util';
import { FeatherModule } from 'angular-feather';
import { NovaAssinaturaV1Model } from '../models/nova-assinatura-v1.model';
import { distinctUntilChanged } from 'rxjs';
import { FormEmissaoNfComponent } from './form-assinatura/components/form-emissao-nf/form-emissao-nf.component';
import { GetIpAddressService } from 'src/app/shared/services/get-ip-address.service';
import { ComunicacaoApiAssinaturaV1Service } from '../services/comunicacao-api-assinatura-v1.service';
import { LoginService } from 'src/app/auth/services/login.service';
import Swal from 'sweetalert2';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import { Router } from '@angular/router';
import { SecurityUtil } from 'src/app/_core/utils/security.util';
import { SplitsV1Model } from 'src/app/pages/models/splits-v1.model';
import { PaymentSaasV1Model } from 'src/app/pages/models/payment-saas-v1.model';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { ConfiguracoesPadraoNF } from './data/configuracoes-padrao-nf';
import { FormAssinaturaComponent } from './form-assinatura/form-assinatura.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalObterUserAccountComponent } from './form-assinatura/components/modal-obter-user-account/modal-obter-user-account.component';
import { SplitSubscriptionV1Model } from '../models/split-subscription-v1.model';
import { LoginModel } from 'src/app/auth/models/login.model';
import { LoginAccessV1Model } from 'src/app/auth/models/login-access-v1.model';

@Component({
  selector: 'app-contratar-assinatura',
  standalone: true,
  imports: [
    CommonModule,
    DetalhesAssinaturaComponent,
    FormAssinaturaComponent,
    FormEmissaoNfComponent,
    FeatherModule
  ],
  templateUrl: './contratar-assinatura.component.html',
  styleUrl: './contratar-assinatura.component.css'
})
export class ContratarAssinaturaComponent implements OnInit, OnDestroy {
  //variáveis de entrada
  @Input() isAlterarPlano?: boolean;

  //variáveis de ambiente
  isUsuarioNaoAutenticado: boolean;
  hasPlanoSelecionado: boolean = false;
  userAccount: AccountV1Model;
  dadosLogin?: LoginModel;
  planoSelecionado?: PlanoSelecionadoV1Model;
  isGerarLinkPagamento?: boolean = false;
  isDadosAssinaturaPreenchidos?: boolean = false;
  novaAssinatura: NovaAssinaturaV1Model = {
    _idAccount: null,
    paymentSaas: {
      cupom: null,
      isCupom: null,
      opcao: null,
      payment: {
        cicloEscolhido: null,
        splitDefinido: null,
        valorACobrar: null
      },
      planos: {
        _id: null,
        ativo: null,
        dtDesativado: null,
        excluded: null,
        planoMensal: null,
        planoSemestral: null,
        planoTrimestral: null,
        split: null
      },
    },
    signature: {
      nextDueDate: null,
      split: null,
      value: null,
      billingType: null,
      creditCard: {
        ccv: null,
        expiryMonth: null,
        expiryYear: null,
        holderName: null,
        number: null,
      },
      creditCardHolderInfo: {
        addressNumber: null,
        cpfCnpj: null,
        email: null,
        mobilePhone: null,
        name: null,
        postalCode: null,
      },
      remoteIp: ''
    },
    isNf: false,
    customer: {
      addressNumber: null,
      cnpj: null,
      email: null,
      mobilePhone: null,
      name: null,
      postalCode: null,
    },
    configNf: {
      municipalServiceId: null,
      municipalServiceCode: null,
      municipalServiceName: null,
      deductions: null,
      effectiveDatePeriod: null,
      daysBeforeDueDate: null,
      receivedOnly: null,
      observations: null,
      taxes: {
        retainIss: null,
        cofins: null,
        csll: null,
        inss: null,
        ir: null,
        pis: null,
        iss: null
      }
    }
  };

  constructor(
    public formReativo: FormReativoService,
    private loaderService: LoaderService,
    public masksUtils: MasksUtil,
    private ipAddressService: GetIpAddressService,
    private readonly comunicacaoComApi: ComunicacaoApiAssinaturaV1Service,
    private readonly loginService: LoginService,
    private modalService: NgbModal,
    private route: Router,
  ) {
    /**
     * Escutando o subject para obter os dados da assinatura,
     * do componente FormAssinatura,
     * caso o usuário opte por realizar o pagamento na nossa plataforma
     */
    FormAssinaturaComponent.dadosAssinatura.pipe(distinctUntilChanged()).subscribe(dados => {
      if (dados) {
        this.isDadosAssinaturaPreenchidos = false;
        this.isGerarLinkPagamento = dados.isLinkPagamento;
        /**
         * Se o valor emitido pelo subject for um valor válido (diferente de null ou undefined),
         * significando que o usuário informou todos os dados solicitados do cartão de crédito, titular do cartão e do endereço de faturamento
         */
        this.userAccount.dadosAccount.isEndPadrao = false;
        this.userAccount.dadosAccount.endereco = {
          cep: dados.cep,
          bairro: dados.bairro,
          cidade: dados.cidade,
          logradouro: dados.logradouro,
          uf: dados.uf,
          numero: dados.numero
        }
        this.novaAssinatura.signature.nextDueDate = this.definindoProximaCobranca();

        if (dados.isLinkPagamento! && dados.nomeTitular && dados.cpfCnpj && dados.celular && dados.emailTitular) {
          /**Caso o usuário opte por receber o link de pagamento */
          this.novaAssinatura.signature.billingType = 'UNDEFINED';

          /**
           * Preenchendo o objeto novaAssinatura.customer 
           * que será enviado para a api, 
           * com os dados informados do titular do cartão
           */
          this.novaAssinatura.customer = {
            addressNumber: '259',
            cpf: dados.cpfCnpj,
            cnpj: null,
            mobilePhone: dados.celular,
            email: dados.emailTitular,
            name: dados.nomeTitular,
            postalCode: '75680053'
          };

          this.userAccount.cpf = dados.cpfCnpj! ? dados.cpfCnpj : '';

          /**
           * Garantindo que os dados do cartão não serão enviados, 
           * caso o usuário informe e depois decida que quer receber o link
           */
          this.novaAssinatura.signature.creditCard = {
            number: undefined,
            ccv: undefined,
            expiryMonth: undefined,
            expiryYear: undefined,
            holderName: undefined
          };

          this.novaAssinatura.signature.creditCardHolderInfo = {
            addressNumber: undefined,
            cpfCnpj: undefined,
            mobilePhone: undefined,
            email: undefined,
            name: undefined,
            postalCode: undefined
          };

          this.isDadosAssinaturaPreenchidos = true;
        } else if (!dados.isLinkPagamento) {
          if (dados.numeroCartao!) {
            /**Caso o usuário opte por pagar em nossa plataforma */
            this.novaAssinatura.signature.billingType = 'CREDIT_CARD'

            /**
             * Preenchendo o objeto novaAssinatura.signature.creditCard 
             * que será enviado para a api, 
             * com os dados informados do cartão
             */
            this.novaAssinatura.signature.creditCard = {
              number: dados.numeroCartao,
              ccv: dados.cvv,
              expiryMonth: dados.validade.slice(0, 2),
              expiryYear: dados.validade.slice(2),
              holderName: dados.nomeTitular
            };

            //Preenchendo o objeto novaAssinatura.signature.creditCardHolderInfo que será enviado para a api, com os dados informados do titular do cartão
            this.novaAssinatura.signature.creditCardHolderInfo = {
              addressNumber: dados.numero,
              cpfCnpj: dados.cpfCnpj,
              mobilePhone: dados.celular,
              email: dados.emailTitular,
              name: dados.nomeTitular,
              postalCode: dados.cep
            };

            this.userAccount.cpf = dados.cpfCnpj! ? dados.cpfCnpj : '';

            this.isDadosAssinaturaPreenchidos = true;
          }
        }
      }
    });

    /**
     * Escutando o subject para obter os dados para 
     * emissão de NF do component FormEmissaoNF 
     */
    FormEmissaoNfComponent.dadosEmissaoNF.pipe(distinctUntilChanged()).subscribe(dados => {
      if (dados!) {
        /**
         * Se o valor emitido pelo subject for um valor válido (diferente de null ou undefined),
         * significando que o usuário informou todos os dados solicitados para a emissão da nf
         */
        //O Account.dadosAccount é atualizado com os dados da empresa
        this.userAccount.dadosAccount.cnpjEscritorio = dados.cnpjEmpresa;
        this.userAccount.dadosAccount.emailEscritorio = dados.emailEmpresa;
        this.userAccount.dadosAccount.nomeEscritorio = dados.razaoSocial.toUpperCase();

        //O objeto da nova assinatura é atualizado com os dados da empresa, sendo o customer para emissão da nf
        this.novaAssinatura.customer = {
          cnpj: dados.cnpjEmpresa,
          cpf: null,
          name: dados.razaoSocial.toUpperCase(),
          email: dados.emailEmpresa,
          mobilePhone: this.novaAssinatura.customer.mobilePhone,
          addressNumber: '259',
          postalCode: '75680053',
        }

        //O obj novaAssinatura recebe os dados de configuração da nota fiscal
        this.novaAssinatura.configNf = ConfiguracoesPadraoNF;

        //Informa que será configurado a nota fiscal, para que a api trate devidamente o customer
        this.novaAssinatura.isNf = true;
      } else {
        /**
         * Se o dado retornado for undefined ou null, então significa que o usuário
         * não informou os dados para emissão da nf e o customer que será definido no asaas
         * será o titular do cartão informado.
          */
        this.novaAssinatura.isNf = false;
      }
    });

    PrecosComponent.planoSelecionadoAutenticadoSubject.pipe(distinctUntilChanged()).subscribe(plano => {
      this.planoSelecionado = plano;
    })
  }

  /**
   * Método de inicialização do componente Angular.
   *
   * Este método é executado quando o componente é inicializado e realiza os seguintes passos:
   * 1. Inicia o carregador.
   * 2. Obtém o IP da máquina do usuário.
   * 3. Verifica se o usuário está autenticado na plataforma.
   * 4. Se o usuário estiver autenticado, define a conta do usuário.
   * 5. Se o usuário não estiver autenticado, exibe um modal para login.
   * 6. Verifica se há um plano selecionado e, se houver, define o plano selecionado.
   * 7. Para o carregador após a conclusão dos passos anteriores.
   *
   * @method
   * @lifecycle Angular
   */
  ngOnInit(): void {
    this.loaderService.startLoader(false);
    /**Obtendo o IP da máquina do usuário */
    this.getIpClient();

    setTimeout(() => {
      this.isUsuarioNaoAutenticado = !SecurityUtil.hasToken();
      if (sessionStorage.getItem('dadosLogin')) {
        this.dadosLogin = JSON.parse(atob(sessionStorage.getItem('dadosLogin')));
      }
      /**
       * Verificando se o usuário foi identificado
       */
      if (sessionStorage.getItem('userAccount')) {
        this.definindoUserAccount();
      } else {
        this.fazendoLoginEObtendoOAccountDoUsuario();
      }

      if (PrecosComponent.hasPlanoSelecionado()) {
        this.hasPlanoSelecionado = true;
        this.planoSelecionado = PrecosComponent.getPlanoSelecionadoSessionStorage();
        this.ajustandoSplitParaSubscription(this.planoSelecionado.planoVigente.split);
      }
      this.loaderService.stopLoader();
    }, 1000)
  }

  /**
   * Ao destruir o componente, o planoSelecionado que foi setado 
   * no sessionStorage será removido.
   * 
   * @method
   * @lifecycle Angular
   */
  ngOnDestroy(): void {
    sessionStorage.removeItem('planoSelecionado');
  }

  fazendoLoginEObtendoOAccountDoUsuario() {
    /**
     * Caso não tenha sido identificado, indica
     * que trata-se de um cliente acessando diretamente
     * pelo link nao-assinantes/contratar.
     * Neste caso, é necessário que o usuário logue na plataforma,
     * para que tenhamos os dados do userAccount
     */
    const modalRef = this.modalService.open(
      ModalObterUserAccountComponent,
      {
        size: 'sm',
        centered: true,
        backdrop: 'static',
        keyboard: false,
      }
    );

    modalRef.componentInstance.planoSelecionado = this.planoSelecionado;
    modalRef.componentInstance.isLoginConcluido.subscribe((op: boolean) => {
      if (op!) {
        this.definindoUserAccount();

        this.dadosLogin = {
          email: modalRef.componentInstance.email,
          accesskey: modalRef.componentInstance.accesskey,
        }
      }
    });
  }

  formatandoNumberParaStringEmReal(valor: number) {
    const valorSplitted = valor.toFixed(2).toString().split(".");
    const valorEmReal = `${valorSplitted[0]},${valorSplitted[1]}`
    return valorEmReal;
  }

  /**
   * Função utilizada para calcular o valor do desconto, em caso de cupom aplicado
   * @returns o valor do desconto devidamente formatado em real (string)
   * @method
   */
  definirDescontoRecebido() {
    const valorDesconto = this.planoSelecionado.cupomAplicado.valor * parseInt(this.planoSelecionado.planoSelecionado);
    return this.formatandoNumberParaStringEmReal(valorDesconto);
  }

  /**
   * Define e inicializa a conta de usuário.
   *
   * Esta função captura o objeto `userAccount` armazenado no `sessionStorage` e verifica se o
   * objeto `dadosAccount` existe. Caso não exista, ele é inicializado com valores padrão.
   * Além disso, verifica se o apelido do usuário possui mais de 10 caracteres, e, se necessário,
   * ajusta o apelido para cumprir a restrição de 10 caracteres.
   * @method
   */
  definindoUserAccount() {
    /**Capturando o userAccount setado no sessionStorage */
    this.userAccount = JSON.parse(atob(sessionStorage.getItem('userAccount')));

    /**Verificando se o obj dadosAccount existe no userAccont */
    if (!this.userAccount?.dadosAccount) {
      /**Se não existir, ele será inicializado, para que possa ser setado, posteriormente */
      this.userAccount.dadosAccount = {
        cnpjEscritorio: null,
        emailEscritorio: null,
        isEndPadrao: true,
        nomeEscritorio: null,
        endereco: {
          bairro: null,
          cidade: null,
          logradouro: null,
          uf: null,
          cep: null,
          numero: null,
        }
      }
    };

    /**Verificando se o apelido atribuido no cadastro possui um length maior que 10 (10 é o máximo permitido na API) */
    if (this.userAccount._user.apelido.length > 10) {
      /**
       * Se o length for maior que 10
       */
      const apelido = this.userAccount._user.apelido;

      /**repartindo a cada espaço em branco */
      const apelidoSplitted = apelido.split(' ');

      /**Verificando se o elemento do primeiro indice[0] (primeiro nome) possui o length menor ou igual à 10  */
      if (apelidoSplitted[0].length <= 10) {
        /**Se tiver, o apelido será atualizado para o primeiro nome */
        this.userAccount._user.apelido = apelidoSplitted[0]
      } else {
        /**Se não tiver, o apelido será atualizado para o primeiro nome, cortando do indice 0 ao 9 (10 caracteres) */
        this.userAccount._user.apelido = apelidoSplitted[0].slice(0, 10)
      }
    }
  }

  /**
   * Finaliza a contratação de um novo plano ou assinatura.
   *
   * Esta função realiza os seguintes passos:
   * 1. Preenche uma nova assinatura.
   * 2. Verifica se trata-se de um usuário não autenticado na plataforma.
   * 3. Se o usuário não estiver autenticado, define o ID da conta retornado ao tentar logar.
   * 4. Atualiza os dados da conta do usuário, caso o usuário esteja logado.
   * 5. Verifica se a operação é uma alteração de plano ou uma nova assinatura.
   * 6. Se for uma alteração de plano, exclui o plano anterior e segue para postar uma nova assinatura.
   * 7. Posta a nova assinatura.
   *
   * @method
   */
  async finalizarContratacao() {
    this.preenchendoNovaAssinatura();
    /**
     * Verificando se trata-se de um usuário não autenticado na plataforma
     * sendo caso como assinatura/avaliação expirada(o)
     */
    if (this.isUsuarioNaoAutenticado) {
      /**
       * Se não tiver logado, será setado o idAccount que foi retornado
       * ao tentar logar na plataforma.
       * Obs: Se o usuário não estiver autenticado, e caso ele opte em receber a nota fiscal,
       * passando os dados da empresa, não será possível atualizar o account.dadosAccount deste usuário,
       * neste momento, uma vez que é necessário que o mesmo esteja logado na plataforma para isto (token).
       *
       * Nestes casos, será feito uma avaliação e atualização, 
       * quando o usuário acessar a opção 'Meu perfil', logado na plataforma.
       */
      this.novaAssinatura._idAccount = this.userAccount._idAccount;
    } else {
      /**Se o usuário estiver logado */
      try {
        /**
         * É feito um update no account do usuário, 
         * pois durante o processo, alguns dados são
         * alterados/adicionados 
         */
        await this.loginService.updateAccount({
          _idAccount: this.userAccount._id,
          _idUser: this.userAccount._user._id,
          account: this.userAccount,
          user: this.userAccount._user
        }).subscribe();
      } catch (error) {
        /**Em caso de algum erro durante o processo de update do account */
        TratamentoErrosHttpErrorResponseService.tratarErro(error)
      }
    }

    /**
     * Verificando se trata-se de uma altereção
     * do plano ou uma nova assinatura 
     */
    if (this.isAlterarPlano) {
      /**
       * Se for uma alteração do plano, será realizado uma exclusão do plano anterior
       * E seguirá para que seja feito o post de uma nova assinatura
       */
      const paymentSaas: PaymentSaasV1Model = JSON.parse(atob(sessionStorage.getItem('paymentSaas')))

      await this.comunicacaoComApi.deleteAssinatura({
        _idAccount: this.userAccount._idAccount,
        idSubscription: paymentSaas.subscription.id,
        isConfigNf: paymentSaas.invoices.length === 0 ? false : true
      });
    }
    this.postNovaAssinatura();
  }

  /**
   * Função utilizada para completar os dados
   * da nova assinatura que não foi preenchido,
   * durante o preenchimento dos formulários
   * @method
   */
  preenchendoNovaAssinatura() {
    /**Preenchendo o objeto NovaAssinatura com os dados necessários */
    this.novaAssinatura.paymentSaas.payment = {
      cicloEscolhido: this.planoSelecionado.planoObject.titulo.toLowerCase().trim(),
      valorACobrar: this.planoSelecionado.valorFinal,
      splitDefinido: null
    }

    this.novaAssinatura.signature.value = this.planoSelecionado.valorFinal;

    /**
     * Verificando se foi aplicado algum cupom e setando os dados
     * de acordo com o resultado da verificação
     */
    if (this.planoSelecionado.isCupomAplicado!) {
      /**Se tiver cupom aplicado */
      this.novaAssinatura.paymentSaas.isCupom = true;
      this.novaAssinatura.paymentSaas.cupom = this.planoSelecionado.cupomAplicado;
      this.novaAssinatura.signature.split = this.ajustandoSplitParaSubscription(this.planoSelecionado.cupomAplicado.split)
      this.novaAssinatura.paymentSaas.payment.splitDefinido = this.planoSelecionado.cupomAplicado.split
    } else {
      /**Se não tiver cupom aplicado */
      this.novaAssinatura.signature.split = this.ajustandoSplitParaSubscription(this.planoSelecionado.planoVigente.split)
      this.novaAssinatura.paymentSaas.payment.splitDefinido = this.planoSelecionado.planoVigente.split
    }
    this.novaAssinatura.paymentSaas.planos = this.planoSelecionado.planoVigente;
    this.novaAssinatura.paymentSaas.opcao = this.planoSelecionado.planoObject.titulo;
    this.novaAssinatura.configNf.observations = `Referente à assinatura ${this.planoSelecionado.planoObject.titulo.trim().toLocaleLowerCase()} do Conversor de Extrato Bancário`
    /**Fim Preenchimento do objeto NovaAssinatura */
  }

  /**
   * Função que define a próximaDataCobrança 
   * sendo atribuida ao nextDueDate 
   * @method
   */
  definindoProximaCobranca() {
    const dtHoje: Date = new Date();
    const proxCobranca = dtHoje;

    /**
     * É adicionado um dia à data de hoje. Na API é que é adicionado a quantidade de meses,
     * de acordo com o ciclo de assinatura escolhido
     */
    // proxCobranca.setDate(dia + 1)

    return proxCobranca;

    /**
     * De acordo com a documentação da asaas, em casos de assinatura, a primeira cobrança é realizada no dia determinado em nextDueDate.
     * Como queremos que a cobrança seja imediata, estou passando a data de hoje como vencimento
     */
  }

  /**
   * Função utilizada para ajustar o array 
   * de splits para que fique igual ao
   * solicitado na API
   * @method
   */
  ajustandoSplitParaSubscription(splits: SplitsV1Model[]): SplitSubscriptionV1Model[] {
    const splitsModificadosParaSubscription: SplitSubscriptionV1Model[] = [];
    splits.forEach(split => {
      const splitModificado: SplitSubscriptionV1Model = {
        walletId: split.walletId,
        fixedValue: split.valor * parseInt(this.planoSelecionado?.planoSelecionado)
      }
      splitsModificadosParaSubscription.push(splitModificado)
    })

    return splitsModificadosParaSubscription;
  }

  desabilitarBotaoSubmit() {
    if (!this.isGerarLinkPagamento) {
      if (!this.isDadosAssinaturaPreenchidos) {
        return true
      } else {
        return false
      }
    } else {
      if (this.novaAssinatura.customer.name) {
        return false
      } else {
        return true
      }
    }
  }

  /**
   * Função utilizada para remover a classe show do elemento #off-canvas-detalhes-assinatura
   * @method
   */
  removeShow() {
    const offcanvas = document.getElementById('off-canvas-detalhes-assinatura');
    offcanvas?.classList.remove('show');
  }

  //Funções assincronas

  /**Função utilizada para obter o ip do usuário */
  async getIpClient() {
    await this.ipAddressService.getIPAddress().subscribe({
      next: (resultado: { ip: string }) => {
        this.novaAssinatura.signature.remoteIp = resultado.ip;
      }
    })
  }

  /**
   * Função utilizada para comunicar na api
   * e realizar o post da nova assinatura
   * @method
   */
  async postNovaAssinatura() {
    /**Fazendo um post da nova assinatura */
    this.loaderService.startLoader(false);
    await this.comunicacaoComApi.postNovaAssinatura(this.novaAssinatura).subscribe({
      next: resultado => {
        this.loaderService.stopLoader();
        if (resultado.success) {
          Swal.fire({
            title: resultado.titulo,
            text: resultado.message + this.novaAssinatura.signature.billingType === 'UNDEFINED'
              ? '\nVocê receberá um email da ASAAS para finalizar o pagamento da assinatura.'
              : '',
            icon: 'success'
          }).then(() => {
            this.loaderService.startLoader(false);
            sessionStorage.removeItem('planoSelecionado');
            /**Verificando qual o tipo de solicitação originada */
            if (this.isAlterarPlano) {
              /**Se foi uma alteração de plano */
              sessionStorage.removeItem('paymentSaas');
              this.route.navigate(['/assinantes/meu-perfil']);
            } else if (this.isUsuarioNaoAutenticado) {
              /**
               * Se foi uma renovação de contratação, 
               * onde o usuário já testou e retestou, 
               * com expiração da dataFimAvaliacao 
               */
              if (this.dadosLogin!) {
                /**
                 * Se tiver os dados de login do usuário, 
                 * será realizado o login
                 */
                this.loginService.autenticar(this.dadosLogin).subscribe(
                  async resultado => {
                    if (resultado.success) {
                      const account: LoginAccessV1Model = resultado.data.account;
                      /**
                       * Inicilizando os parâmetros de avaliação do tipo de conta, 
                       * que podem não ser retornados pelo processo de login
                       * impedindo possíveis erros de 'can not read properties of undefined'
                       */
                      !account.isAssinatura ? account.isAssinatura = false : null;
                      !account.isManager ? account.isManager = false : null;
                      !account.isParceiro ? account.isParceiro = false : null;
                      !account.isTeste ? account.isTeste = false : null;
                      !account.isReteste ? account.isReteste = false : null;

                      /**Setando o token e o account no localStorage */
                      SecurityUtil.set(
                        account,
                        resultado.data.token,
                      );
                      this.getUserAccById();
                    }
                  }
                )
              } else {
                this.loaderService.stopLoader();
                this.route.navigate(['/auth/login']);
              }
            } else {
              /**
               * Se o usuário selecionou 'contratar' estando logado na plataforma, 
               * ou se o usuário optou por contratar o plano direto, sem criar a conta de avaliação primeiro.
               * (Isso ocorre porque, ao optar pela contratação do plano sem optar pelo teste grátis,
               * criamos uma conta de avaliação, para logar o usuário e ele finaliza a contratação em um ambiente
               * logado.)
               * 
               * Será feito uma atualização no userAccount que é setado no sessionStorage, 
               * para que as variáveis de avaliação do tipo de conta sejam atualizadas 
               * de acordo com o resultado do pagamento da assinatura 
               * e o usuário será redirecionado para o painel do conversor
               */
              this.getUserAccById();
            }
          });
        } else {
          this.loaderService.stopLoader();
          let message = '';
          /**
           * capturando a messagem de error, se o resultado.error typeof == array
           * capturando então o error.description
           */
          if (resultado.error!) {
            if (typeof (resultado.error) !== 'string') {
              resultado.error.forEach(element => {
                message += element.description + ' '
              });

            } else {
              message = resultado.error
            }
          } else {
            message = resultado.message
          }
          Swal.fire(
            'Algo deu errado!',
            message,
            'error'
          );
        }
      },
      error: err => {
        this.loaderService.stopLoader();
        TratamentoErrosHttpErrorResponseService.tratarErro(err);
      }
    })
  }

  /**
   * Função utilizada para setar o Account do usuário no 
   * sessionStorage e emitir o valor no subject userLogado 
   * @method
   */
  private async getUserAccById() {
    await this.loginService.getContaById().subscribe({
      next: async resultado => {
        sessionStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)))
        LoginComponent.userLogado.next(resultado.data);
        this.route.navigate(['/assinantes/conversor']);
      }
    })
  }
}