import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RenovarContratacaoComponent } from './renovar-contratacao/renovar-contratacao.component';
import { MasterPageComponent } from '../assinantes/master-page/master-page.component';
import { FormNovoParceiroComponent } from './form-novo-parceiro/form-novo-parceiro.component';
import { TabelaClientesAvaliacaoComponent } from './tabela-clientes-avaliacao/tabela-clientes-avaliacao.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      {
        path: 'contratar',
        component: RenovarContratacaoComponent
      },
      {
        path: 'parceiro',
        component: FormNovoParceiroComponent
      },
      {
        path: 'tabela-usuarios-avaliacao',
        component: TabelaClientesAvaliacaoComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NaoAssinantesRoutingModule { }
