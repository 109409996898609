export const environment = {
  production: true,

  /**
   * Dados para comunicação api conversor
   */
  apiConversor: 'https://apiofx.epigrafo.com.br', // Servidor produção
  accessTokenConversor: 'ZXBnX1ZVVlN0Ums5MEt3d1RxdkpWUVpHZFdvS3RVQ0NwSGNoU3cwZWlyMVI0cXhSMFBCcjBmUTlVZldxQlZGN2t6b1pmZ0IzQVk0RTcyVDJDa2xZQWxYY3pZWGVlSXg2N3RBUFdFRTEjIw==',

  /**
   * Dados para comunicação api asaas
   */
  apiAsaas: 'https://asaas1.epigrafo.com',
  accessTokenAsaas: 'R3k0Wmk2THExU3c3TG01TWQyR3Q0VXM3UW42U3ExRWI3VWk4R3UxWXoyRnM4UWU0VHA1WnI2V3kyUXU5SWc0TXo0U2UxRnk2WGs3WWIxQmc1VnQ5QXA2Vm0yWWU3THE2UGQzUw==',
};