import { Component, Input, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { MasksUtil } from 'src/app/shared/services/masks.util';
import { FormEmissaoNfComponent } from './components/form-emissao-nf/form-emissao-nf.component';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule, NgbAccordionModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { ViaCepService } from 'src/app/shared/services/via-cep.service';
import { EnderecoV1Model } from 'src/app/auth/models/account-v1.model';

@Component({
  selector: 'app-form-assinatura',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormEmissaoNfComponent,
    NgbNavModule,
    NgbTooltipModule,
    FeatherModule,
    NgbAccordionModule
  ],
  templateUrl: './form-assinatura.component.html',
  styles: ``
})

export class FormAssinaturaComponent {
  //variáveis de ambiente
  formDadosAssinatura: FormGroup;
  isCepValido: boolean = false;
  isUtilizarMesmoEmail: boolean = false;
  isUtilizarMesmoNome: boolean = false;
  isUtilizarMesmoCPF: boolean = false;
  isUtilizarMesmoCelular: boolean = false;
  @Input() emailUsuario?: string;
  @Input() nomeUsuario?: string;
  @Input() telefoneUsuario?: string;
  @Input() cpfUsuario?: string;
  labelNome: string = 'Titular do Cartão:';
  isEmitirNf: boolean = false;
  helpLink: string = 'https://doc.extratoofx.com/docs/tutorial-utilizando/planos#cupom-de-desconto'
  isGerarLinkPagamento: boolean = false;

  static dadosAssinatura = new Subject<DadosAssinaturaV1Model>();

  constructor(
    private fb: FormBuilder,
    public formReativo: FormReativoService,
    public masksUtils: MasksUtil,
    private viaCepService: ViaCepService,
    private loaderService: LoaderService,
    private renderer: Renderer2
  ) {
    this.formDadosAssinatura = this.fb.group(this.montarFormDadosAssinatura());
  }

  /**
   * Cria o formulário de dados de assinatura.
   *
   * Retorna um objeto com as propriedades do formulário, incluindo:
   *
   * - nomeTitular: nome do titular do cartão;
   * - cpfCnpjTitular: CPF ou CNPJ do titular do cartão;
   * - emailTitular: e-mail do titular do cartão;
   * - celular: celular do titular do cartão;
   * - numeroCartao: número do cartão de crédito;
   * - cvv: código de segurança do cartão de crédito;
   * - validade: data de validade do cartão de crédito;
   * - cepTitular: CEP do endereço do titular do cartão;
   * - logradouro: logradouro do endereço do titular do cartão;
   * - numeroEndereco: número do endereço do titular do cartão;
   * - bairro: bairro do endereço do titular do cartão;
   * - cidade: cidade do endereço do titular do cartão;
   * - estado: estado do endereço do titular do cartão.
   *
   * @returns {Object} O objeto com as propriedades do formulário.
   */
  montarFormDadosAssinatura() {
    return {
      nomeTitular: [
        '',
        Validators.required
      ],
      cpfCnpjTitular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
        ])
      ],
      emailTitular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      celular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ])
      ],
      numeroCartao: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(16),
          Validators.maxLength(16)
        ])
      ],
      cvv: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(3)
        ])
      ],
      validade: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6)
        ])
      ],
      cepTitular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/\d+/)
        ])
      ],
      logradouro: '',
      numeroEndereco: [
        '',
        Validators.required,
      ],
      bairro: '',
      cidade: '',
      estado: '',
    }
  }

  /**
   * Retorna os controles do formulário de dados de assinatura.
   *
   * @returns {Object} O objeto com as propriedades dos controles do formulário.
   */
  get controls() {
    return this.formDadosAssinatura.controls;
  }

  /**
   * Manipula a alteração de gerar link de pagamento.
   *
   * Esta função é executada quando há uma alteração na opção de gerar link de pagamento.
   * Remove ou adiciona os controles do formulário relacionados ao cartão de crédito
   * e atualiza o label do nome de acordo.
   * Emite os dados do cartão após as alterações.
   *
   * @method
   */
  onChangeGerarLinkPagamento() {
    // Remove os controles do formulário relacionados ao cartão de crédito
    // se a opção de gerar link de pagamento estiver selecionada.
    if (this.isGerarLinkPagamento!) {
      this.formReativo.removeControlsFromForm(
        this.formDadosAssinatura,
        'cvv',
        'numeroCartao',
        'validade',
        'cepTitular',
        'logradouro',
        'numeroEndereco',
        'bairro',
        'cidade',
        'estado'
      )
      // Atualiza o label do nome de acordo.
      this.labelNome = 'Nome Completo:'
    } else {
      // Adiciona os controles do formulário relacionados ao cartão de crédito
      // se a opção de gerar link de pagamento não estiver selecionada.
      this.labelNome = 'Titular do Cartão:'
      this.formReativo.addControlsToForm(
        this.formDadosAssinatura,
        '',
        [Validators.required],
        'cvv', 'numeroCartao', 'validade', 'cepTitular', 'numeroEndereco'
      );
      this.formReativo.addControlsToForm(
        this.formDadosAssinatura,
        '',
        [],
        'bairro', 'cidade', 'estado', 'logradouro'
      );
    }

    // Emite os dados do cartão após as alterações.
    this.emitirDadosAssinatura();
  }

  /**
   * Emite os dados do formulário de assinatura para o componente pai.
   * Se o formulário for válido e a opção de gerar link de pagamento estiver
   * selecionada, emite os dados com o campo isLinkPagamento como true e os demais
   * campos como undefined. Caso contrário, emite os dados com o campo isLinkPagamento
   * como false e os demais campos com os valores informados.
   *
   * @method
   */
  emitirDadosAssinatura() {
    // Verifica se o formulário é válido
    if (this.formDadosAssinatura.valid!) {
      // Verifica se a opção de gerar link de pagamento está selecionada
      if (this.isGerarLinkPagamento!) {
        // Prepara os dados a serem emitidos com isLinkPagamento como true
        const dadosAEmitir: DadosAssinaturaV1Model = {
          nomeTitular: this.controls['nomeTitular'].value,
          celular: this.controls['celular'].value,
          cpfCnpj: this.controls['cpfCnpjTitular'].value,
          emailTitular: this.controls['emailTitular'].value,
          isLinkPagamento: this.isGerarLinkPagamento,
          cvv: undefined,
          numeroCartao: undefined,
          validade: undefined,
          bairro: '',
          cidade: '',
          logradouro: '',
          numero: '',
          uf: '',
          cep: '',
        }
        // Emite os dados para o componente pai
        FormAssinaturaComponent.dadosAssinatura.next(dadosAEmitir);
      } else {
        // Verifica se a validade informada é maior que a data atual
        if (this.verificarValidadeInformada(this.controls['validade'].value)) {
          // Prepara os dados a serem emitidos com os valores informados
          const dadosAEmitir = {
            nomeTitular: this.controls['nomeTitular'].value,
            celular: this.controls['celular'].value,
            cpfCnpj: this.controls['cpfCnpjTitular'].value,
            emailTitular: this.controls['emailTitular'].value,
            isLinkPagamento: this.isGerarLinkPagamento,
            cvv: this.controls['cvv'].value,
            numeroCartao: this.controls['numeroCartao'].value,
            validade: this.controls['validade'].value,
            bairro: this.controls['bairro'].value,
            cidade: this.controls['cidade'].value,
            logradouro: this.controls['logradouro'].value,
            numero: this.controls['numeroEndereco'].value,
            uf: this.controls['estado'].value,
            cep: this.controls['cepTitular'].value,
          }
          // Emite os dados para o componente pai
          FormAssinaturaComponent.dadosAssinatura.next(dadosAEmitir);
        } else {
          // Define erro caso a validade informada não seja maior que a data atual
          this.controls['validade'].setErrors({ notEqual: true });
        }
      }
    } else {
      // Emite apenas o estado da opção isLinkPagamento se o formulário não for válido
      FormAssinaturaComponent.dadosAssinatura.next({ isLinkPagamento: this.isGerarLinkPagamento });
    }
  }

  /**
   * Verifica se a data informada para a validade do cartão é maior que a data atual
   * 
   * Esta função recebe uma string no formato MM/AAAA e verifica se a data informada
   * é maior que a data atual. Se a data informada for maior que a data de hoje, retorna
   * true. Caso contrário, retorna false.
   * 
   * @param {string} validade - Data informada no formato MM/AAAA
   * @returns {boolean} - True se a data informada for maior que a data atual, false caso contr rio
   */
  verificarValidadeInformada(validade: string): boolean {
    const mes = validade.slice(0, 2); // Mês informado
    const ano = validade.slice(2); // Ano informado

    const dataInformada = new Date(parseInt(ano), (parseInt(mes) - 1)); // Data informada
    const dataAtual = new Date(); // Data atual

    /**
     * Se a data informada for maior que a data de hoje, retorna true
     * Caso contrário, retorna false
     */
    if (dataInformada.toISOString() > dataAtual.toISOString()) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Emite um evento para mostrar ou esconder o formulário de emissão de NF
   * 
   * @returns {void}
   */
  emitirNF(): void {
    this.isEmitirNf = !this.isEmitirNf;

    /**
     * Se o formulário de emissão de NF estiver escondido, emite um evento para 
     * resetar os dados para emissão da NF
     */
    if (!this.isEmitirNf) {
      FormEmissaoNfComponent.dadosEmissaoNF.next(undefined);
    }
  }

  /**
   * Função acionada quando o input do cep for alterado
   */
  async aoInformarOCep() {
    // Verifica se o controle 'cepTitular' é válido (atingiu o minLength e maxLength)
    if (this.controls['cepTitular'].valid!) {
      const cep = this.controls['cepTitular'].value;

      // Inicia o loader para indicar processamento
      this.loaderService.startLoader(false);
      
      // Faz a busca do endereço pelo CEP informado
      await this.viaCepService.buscarEnderecoPorCep(cep).subscribe({
        next: data => {
          // Verifica se não houve erro na busca do CEP
          if (!data.erro) {
            // Atualiza os valores do formulário com os dados do endereço retornado
            this.formDadosAssinatura.patchValue({
              logradouro: data.logradouro,
              bairro: data.bairro,
              cidade: data.localidade,
              estado: data.uf
            });

            // Desabilita os campos de endereço, pois foram preenchidos automaticamente
            this.controls['bairro'].disable();
            this.controls['cidade'].disable();
            this.controls['estado'].disable();

            // Marca o CEP como válido
            this.isCepValido = true;
          } else {
            // Marca o CEP como inválido caso ocorra um erro
            this.isCepValido = false;
          }
          // Para o loader após o processamento
          this.loaderService.stopLoader();
        },
        error: () => {
          // Para o loader em caso de erro e define erro de padrão no controle do CEP
          this.loaderService.stopLoader();
          this.controls['cepTitular'].setErrors([{ pattern: true }]);
        }
      });
    }
  }

  /**
   * Preenche o formulário com os dados do usuário
   * 
   * @remarks
   * Esta função é acionada quando o usuário clica no botão "Preencher com meus dados"
   * e preenche o formulário com os dados do usuário
   */
  preencherFormComDadosDoUsuario() {
    /**
     * Preenche o formulário com os dados do usuário
     * 
     * @param {string} nomeUsuario - Nome do usuário
     * @param {string} emailUsuario - Email do usuário
     * @param {string} telefoneUsuario - Telefone do usuário
     * @param {string} cpfUsuario - CPF do usuário
     */
    this.formDadosAssinatura.patchValue({
      'nomeTitular': this.nomeUsuario,
      'emailTitular': this.emailUsuario,
      'celular': this.telefoneUsuario,
      'cpfCnpjTitular': this.cpfUsuario
    });

    /**
     * Se o CPF do usuário estiver vazio, foca no campo
     * 
     * @remarks
     * Se o CPF do usuário estiver vazio, foca no campo para que o usuário possa
     * preencher o campo
     */
    if (!this.cpfUsuario) {
      this.renderer.selectRootElement('#cpfCnpjTitular')?.focus();
    } else if (!this.telefoneUsuario) {
      /**
       * Se o telefone do usuário estiver vazio, foca no campo
       * 
       * @remarks
       * Se o telefone do usuário estiver vazio, foca no campo para que o usuário
       * possa preencher o campo
       */
      this.renderer.selectRootElement('#celular')?.focus();
    }
  }

  /**
   * Esta função é acionada quando o usuário completa o preenchimento de um campo
   * e move o foco para o próximo campo
   * 
   * @param {string} formControl - Nome do formControl que foi preenchido
   * @param {number} maxLength - Tamanho máximo do campo
   * @param {string} proxInput - Nome do próximo campo que deve ser focado
   * 
   * @remarks
   * Esta função é usada para automatizar o preenchimento do formulário. Ela é
   * usada em campos que tem um tamanho máximo, como o CPF ou o CEP, e move o
   * foco para o próximo campo apenas quando o campo atual está completo.
   */
  proximoInput(formControl: string, maxLength: number, proxInput: string) {
    if (this.controls[formControl]?.value.length == maxLength) {
      this.renderer.selectRootElement(`#${proxInput}`)?.focus();
    }
  };
}

interface DadosAssinaturaV1Model extends EnderecoV1Model {
  nomeTitular?: string;
  cpfCnpj?: string;
  celular?: string;
  emailTitular?: string;
  numeroCartao?: string;
  cvv?: string;
  validade?: string;
  cpfUsuario?: string;
  isLinkPagamento?: boolean;
}